<template>
  <div class="card card-questions mb-4">
    <div class="card-header">
      <p>
        Criada por <span class="font-bold">{{ criadaPor }}</span>
      </p>
      <div
        class="card-header__actions d-flex justify-content-md-between align-items-md-end"
      >
        <ul class="card-list list-inline mb-0" id="badgeList">
          <li class="list-inline-item">
            <span class="badge">
              <strong>{{ questaoProp.disciplina.text }}</strong>
            </span>
          </li>
          <li
            v-for="assunto in assuntos"
            :key="assunto.id"
            class="list-inline-item"
          >
            <span class="badge">{{ assunto.text }}</span>
          </li>
          <li class="list-inline-item">
            <span class="badge">{{ questaoProp.tipo }}</span>
          </li>
          <li class="list-inline-item">
            <span class="badge">{{ questaoProp.nivel }}</span>
          </li>
        </ul>

        <ul v-if="minhaQuestao" id="actionsList">
          <li class="list-inline-item">
            <button class="btn btn-classic" @click="restore()">
              Recuperar
            </button>
          </li>
          <li class="list-inline-item">
            <button class="btn btn-danger" @click="deletar()">
              Excluir Permanentemente
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body mb-3">
      <p class="card-title">Enunciado</p>

      <div class="content mb-2" v-html="editorData" />
      <div class="form-group form-group-repeater d-flex flex-column">
        <label for="">
          <button
            class="btn btn-outline btn-outline__sec"
            @click="toggleRespostas"
          >
            <i
              class="fas fa-eye"
              :class="[showAlternativas ? 'fa-eye-slash' : 'fa-eye']"
            />
            Respostas
          </button>
        </label>

        <div v-if="showAlternativas">
          <div
            v-for="(alternativa, index) in alternativas"
            :key="alternativa.id"
            class="input-group mb-2"
          >
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  v-model="alternativas[index].isCorreta"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  disabled
                />
              </div>
              <span class="input-group-text">a)</span>
            </div>

            <div class="" />

            <input
              v-model="alternativas[index].texto"
              type="text"
              class="form-control"
              aria-label="Dollar amount (with dot and two decimal places)"
              readonly
            />
          </div>
        </div>
      </div>
      <div
        class="card-header__actions d-flex justify-content-md-between align-items-md-end"
      >
        <ul class="card-list list-inline mb-0"></ul>
        <ul class="card-list list-inline mb-0">
          <li
            v-if="isListInseridaProva && ordemProva == 'Sequencial'"
            class="list-inline-item"
          >
            <div class="input-group" v-if="isListInseridaProva">
              <div class="input-group-prepend">
                <div id="btnGroupAddon" class="input-group-text">
                  Valor da questão
                </div>
              </div>
              <input
                type="number"
                min="1"
                class="form-control"
                style="width: 100px"
                aria-describedby="btnGroupAddon"
                v-model="peso"
                @change="soma(peso)"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  components: {},
  props: {
    questaoProp: {
      type: Object,
    },
    ordemProva: {
      type: String,
    },
    isListProva: {
      type: Boolean,
      required: true,
      default: false,
    },
    isListInseridaProva: {
      type: Boolean,
      required: true,
      default: false,
    },
    previewProva: {
      type: Boolean,
      required: false,
      default: false,
    },
    preview: {
      type: Boolean,
      required: false,
      default: true,
    },
    emailCriador: {
      type: String,
    },
  },
  data() {
    return {
      showAlternativas: false,
      alternativas: this.questaoProp.alternativas,
      editorData: this.questaoProp.enunciado,
      assuntos: this.questaoProp.assuntos,
      criadaPor: this.questaoProp.criadaPor.email,
      peso: this.questaoProp.peso,
      minhaQuestao: true,
    };
  },
  created() {
    // console.log(this.questaoProp);
    console.log(this.emailCriador);
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$api
        .get("users/info")
        .then((response) => {
          this.user = response.data;
          console.log("eu", this.user);
    console.log(this.emailCriador);
          if (this.user.user.email == this.emailCriador) {
            this.minhaQuestao = true;
          } else {
            this.minhaQuestao = false;
          }
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    deletar() {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção dessa questão?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");

          this.$api
            .delete("questaos/" + this.questaoProp.id)
            .then(() => {
              this.$root.$emit("Spinner::hide");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Questão removido com sucesso!",
                showConfirmButton: false,
                timer: 1500,
              });
              setTimeout(function() {
                location.reload();
              }, 1000);
              // this.loadData();
              // window.location.reload()
            })
            .catch((error) => {
              console.log(error.response.data);
              Swal.fire({
                position: "center",
                icon: "error",
                title: error.response.data.detail,
                showConfirmButton: false,
                timer: 1500,
              });
            });
          this.$root.$emit("Spinner::hide");
        }
      });
    },
    restore() {
      this.$api
        .put("questaos/" + this.questaoProp.id + "/recovery", {})
        .then((d) => {
          console.log(d);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Questão restaurado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.items.splice(index, 1);
          setTimeout(function() {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    deleteQuestaoDaProva() {
      this.$parent.deletaQuestao(this.questaoProp);
    },
    insereNaProva() {
      this.$parent.insereQuestao(this.questaoProp);
    },
    toggleRespostas() {
      this.showAlternativas = !this.showAlternativas;
    },
    deleteQuestao() {
      console.log(this.questaoProp);
      this.$parent.deleta(this.questaoProp);
    },
    favoritarQuestao() {
      console.log(this.questaoProp);
      this.$parent.favoritarQuestao(this.questaoProp);
    },
    soma(data) {
      console.log(data, this.questaoProp);
      this.$parent.somaPeso(data, this.questaoProp);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge_assunto {
  background-color: #26a54e !important;
  color: #fff !important;
}
.badge {
  width: 100%;
  height: auto;
  white-space: normal;
  text-align: start;
}

.card-questions .card-body .content img {
  width: 100% !important;
}

.card-header__actions{
  flex-direction: column-reverse;
}

#badgeList{
  align-self: start;
}

#actionsList {
  align-self: end;
}
</style>
