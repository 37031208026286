<template>
  <main
    id="content"
    class="main-content page-questions pt-0 bg-oct"
    role="main"
  >
    <div
      class="
        page-questions__header
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <router-link to="banco-de-questoes" class="link fs-24">
        <i class="fas fa-chevron-left mr-3" /> Voltar
      </router-link>
      <span style="color: #fff; font-size: 20px">Lixeira de Questões</span>
      <router-link :to="{ name: 'questao_create' }" class="link font-bold">
      </router-link>
    </div>

    <div class="page-questions__content">
      <!-- Start Content left-->
      <div class="content-left">
        <QuestaoFilter
          :filtro="filtro"
          :disciplinasDisponiveis="disciplinasDisponiveis"
          :assuntosDisponiveis="assuntosDisponiveis"
          :anosDisponiveis="anosDisponiveis"
        />
      </div>
      <!-- End Content left-->

      <!-- Start Content center -->
      <div class="content-right px-4">
        <p class="mb-4">
          Exibindo {{ questoes.length }} questões de
          {{ total }} encontradas
        </p>
        <QuestaoListLixo
          v-for="questao in questoes"
          :key="questao.id"
          :questao-prop="questao"
          :is-list-prova="false"
          :is-list-inserida-prova="false"
          :emailCriador="questao.criadaPor.email"
        />

        <Pagination
          :total="total"
          :page="this.page"
          :total-paginas="this.totalPaginas"
          :itens-per-page="this.itensPerPage"
          @change-page="onChangePage"
        >
        </Pagination>
      </div>
      <!-- End Content center -->
    </div>
  </main>
</template>

<script>
import Pagination from "@/components/questoes/Pagination.vue";
import QuestaoListLixo from "@/components/questoes/QuestaoListLixo";
import QuestaoFilter from "@/components/questoes/QuestaoFilter";
import Swal from "sweetalert2";
import { constantes } from "@/constants";

export default {
  components: {
    QuestaoListLixo,
    Pagination,
    QuestaoFilter,
  },
  data() {
    return {
      disciplinasDisponiveis: [],
      assuntosDisponiveis: [],
      anosDisponiveis: [],
      showRespostas: false,
      page: 1,
      total: 0,
      itensPerPage: 10,
      questoes: [],
      user: [],
      totalPaginas: 0,
      filtro: {
        page: 1,
        size: 1,
        disciplinaSelecionada: null,
        assuntosSelecionados: null,
        anoSelecionado: null,
        tipo: "",
        search: "",
        origem: "",
      },
    };
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$api
        .get("users/info")
        .then((response) => {
          this.user = response.data;
          console.log(this.user)
          this.$root.$emit("Spinner::hide");
          this.loadQuestoes();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    deleta(questao) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção da questão?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");
          this.$api.delete("questaos/" + questao.id).then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Questão removida com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            });

            this.loadQuestoes();
          });
        }
      });
    },
    onChangePage(value) {
      this.filtro.page = value;
      this.loadQuestoes();
    },
    loadQuestoes() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.filtro.size = this.itensPerPage;
      this.page = this.filtro.page;
      this.$api
        .post("/questoes-paginacao-trash", this.filtro)
        .then((response) => {
          this.total = response.data.totalItens;
          this.totalPaginas = response.data.paginas;
          this.questoes = [];

          response.data.data.forEach((res) => {
            if(idInstituicao == 0 || res.criadaPor.email == this.user.user.email){
              this.questoes.push(res);
            }
          });
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    filtrar() {
      this.loadQuestoes();
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        this.disciplinasDisponiveis = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAssuntos(idDisciplina) {
      this.$api.get("assuntos-disciplina/" + idDisciplina).then((response) => {
        this.assuntosDisponiveis = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
    loadAnos() {
      this.$api.get("ano_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.anosDisponiveis.push(res);
          }
        });
        // this.anosDisponiveis = response.data;
        this.anosDisponiveis.map(function (entry) {
          entry.text = entry.titulo;
        });
        this.$root.$emit("Spinner::hide");
      });
    },
  },
};
</script>
